/* ----------------------------------------------------------------------------
 * Variables
 * ------------------------------------------------------------------------- */

$xxs: 210px;
$xs: 576px;
$s: 768px;
$m: 992px;
$l: 1200px;
$xl: 1420px;
$xxl: 1824px;

$breakpoints: (
        xs: $xs,
        s: $s,
        m: $m,
        l: $l,
        xl: $xl,
        xxl: $xxl
);

$secondary-color: #428bca;
$link-color: #428bca;

$gray: #f4f4f4;
$team-arrow-width: 40px;

$article-grit-title-color: #000;
$article-grit-excerpt-color: #666;
$article-grit-excerpt-hover-color: #666;
$article-grit-title-hover-color: #000;
$article-grid-item-background: #f6f6f6;
$article-grid-item-hover-background: #f9f9f9;

$article-grid-large-title-sizes: (
        null : 16px,
        xs : 24px,
        s : 32px,
        m : 26px,
        l : 35px,
        xl: 48px,
        xxl: 56px
);

$article-grid-title-sizes: (
        null : 18px,
        xs : 16px,
        s : 18px,
        m : 18px,
        l : 21px,
        xl: 24px,
        xxl: 28px
);

$article-grid-count-sizes: (
        null : 21px,
        xs : 21px,
        s : 24px,
        m : 24px,
        l : 28px,
        xl: 32px,
        xxl: 38px
);

$article-grit-arrow-width: 20px;
