/*!
Theme Name: Giss theme
Version: 1.0
Description: Giss theme
Author: Marena
template: bb-theme

*/

/* Add your custom styles here... */


/* ----------------------------------------------------------------------------
 * Mixins & Variables
 * ------------------------------------------------------------------------- */
//@import "../bower_components/compass-sass-mixins/lib/compass";
@import "partials/mixins";
@import "partials/variables";

/* ----------------------------------------------------------------------------
 * Normalize & Santize
 * ------------------------------------------------------------------------- */
@import "vendor/normalize";
@import "vendor/sanitize";
@import "partials/home";
@import "partials/home-articles";
@import "partials/post";

/* ----------------------------------------------------------------------------
 * Structure
 * ------------------------------------------------------------------------- */
//@import "partials/icons";
//@import "partials/layout";

