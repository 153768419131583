.article-grid-wrapper {
  //background-color: #ddd;
  //border-bottom: solid 1px #ccc;
  //border-top: solid 1px #ccc;
}

.article-grid {
  border: solid 1px #ddd;
  max-width: 1600px;
  margin: 0 auto;

  .article-1 {
    display: flex;
  }

  .item {
    @include article-item();
    @include article-item-variant('left', $article-grid-item-background);
  }

  .article-4 {

    display: flex;
    margin: 0 auto;
    padding: 30px 0;

    @include min($m) {
      padding: 0;
    }

    .item {
      @include article-item-variant('left', #fff);

      .image {
        overflow: visible;
        text-align: center;
        img {
          width: auto;
          max-width: 85%;
        }
        .count {
          position: absolute;
          display: block;
          color: #fff;
          top: 30%;
          left: 30%;
          font-weight: bold;
          @include font-size($article-grid-count-sizes);
        }
      }
    }
  }

  @include max($xs) {
    .article-2,
    .article-5 {
      .item {
        @include article-item-variant('right', #fff);
      }
    }
  }

  @include minmax($xs, $m) {
    .article-2,
    .article-3 {
      .item {
        @include article-item-variant('right', #fff);
      }
    }
  }

  @include min($m) {
    .article-2 {
      .item {
        @include article-item-variant('left', $article-grid-item-background);
      }
    }
    .article-3 {
      .item {
        @include article-item-variant('right', #fff);
      }
    }
    .article-5 {
      .item {
        @include article-item-variant('left', #fff);
      }
    }
  }
}
