/* ----------------------------------------------------------------------------
 * Mixins
 * ------------------------------------------------------------------------- */

/*
 * Mixin for clearfix
 * @include clearfix;
*/
@mixin clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

/*
 * @font-face mixin
 * Bulletproof font-face via Font Squirrel
 * @include fontface('family', 'assets/fonts/', 'myfontname');
 */
@mixin fontface($font-family, $font-url, $font-name) {
  @font-face {
    font: {
      family: $font-family;
      style: normal;
      weight: normal;
    }

    src: url($font-url + '/' + $font-name + '.eot');
    src: url($font-url + '/' + $font-name + '.eot#iefix') format('embedded-opentype'),
    url($font-url + '/' + $font-name + '.woff') format('woff'),
    url($font-url + '/' + $font-name + '.ttf') format('truetype'),
    url($font-url + '/' + $font-name + '.svg#' + $font-name) format('svg');
  }
}

/**
 * IMAGE RETINA
 * @include image-2x(/img/image.png, 100%, auto);
 */
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}

@mixin min($point) {
  @media screen and (min-width: $point) {
    @content;
  }
}

@mixin minmax($from, $to) {
  @media screen and (min-width: $from) and (max-width: ($to - 1)) {
    @content;
  }
}

@mixin minh($point) {
  @media screen and (min-height: $point) {
    @content;
  }
}

@mixin max($point) {
  @media screen and (max-width: ($point - 1)) {
    @content;
  }
}

@mixin reverse {
  .content {
    order: 1;
    text-align: right;
  }
  .image {
    order: 2;

    &:after {
      //background-color: #ffffff;
      right: inherit;
      left: -1*($team-arrow-width / 2);
    }
  }

  &:hover {
    background-color: #fafafa;

    .content h2 {
      margin-right: -7px;
    }
  }
}

@mixin maxh($point) {
  @media screen and (max-height: ($point - 1)) {
    @content;
  }
}

// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin transition-property($property...) {
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -webkit-transition-property: $property;
  transition-property: $property;
}

@mixin transition-duration($duration...) {
  -moz-transition-property: $duration;
  -o-transition-property: $duration;
  -webkit-transition-property: $duration;
  transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    } @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  } @else {
    font-size: $fs-font-size;
  }
}

@mixin grayscale($value) {
  -webkit-filter: #{"grayscale(#{$value})"};
  -moz-filter: #{"grayscale(#{$value})"};
  filter: #{"grayscale(#{$value})"};
}

//
// Viewport sized typography with minimum and maximum values
//
// @author Eduardo Boucas (@eduardoboucas)
//
// @param {Number}   $responsive  - Viewport-based size
// @param {Number}   $min         - Minimum font size (px)
// @param {Number}   $max         - Maximum font size (px)
//                                  (optional)
// @param {Number}   $fallback    - Fallback for viewport-
//                                  based units (optional)
//
// @example scss - 5vw font size (with 50px fallback),
//                 minumum of 35px and maximum of 150px
//  @include responsive-font(5vw, 35px, 150px, 50px);
//
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}

@mixin article-item() {

  display: flex;
  width: 100%;
  align-items: center;
  @include transition(background-color 0.3s);

  .image {
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      @include transition(all 0.5s);
    }
  }

  .content {
    padding: 15px 25px;
    h2, h3 {
      @include transition(all 0.3s);
      color: $article-grit-title-color;
      margin-bottom: 0;
    }
    h2 {
      @include font-size($article-grid-large-title-sizes);
      @include min($s) {
        margin-bottom: 30px;
      }
    }
    h3 {
      @include font-size($article-grid-title-sizes);
    }

    .excerpt {
      color: $article-grit-excerpt-color;

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }

      &:hover {
        color: $article-grit-excerpt-hover-color;
      }
    }

    .more {
      margin: 8px 0 0 0;
      display: inline-block;
      font-size: 14px;
      color: #888;

      @include min($m) {
        &:before {
          content: '';
          display: block;
          border-top: solid 2px #000;
          padding-bottom: 8px;
        }
      }
    }
  }

  &:hover {
    text-decoration: none;

    .image {
      img {
        @include scale(1.1);
      }
    }

    .content {
      h2, h3 {
        color: $article-grit-title-hover-color;
      }
    }
  }
}

@mixin article-item-variant($position, $background-color) {

  background-color: $background-color;

  .image {
    @if $position == 'right' {
      order: 2;
    }

    @if $position == 'left' {
      &:after {
        @include article-arrow('right', $background-color);
      }
    } @else {
      &:after {
        @include article-arrow('left', $background-color);
      }
    }
  }

  .content {
    @if $position == 'right' {
      order: 1;
      text-align: right;
    }
  }

  &:hover {
    .content {
      h2, h3 {
        @if $position == 'left' {
          @include translate(-5px, 0);
        } @else {
          @include translate(5px, 0);
        }
      }
    }
  }
}

@mixin article-arrow($direction, $color) {
  position: absolute;
  content: '';
  width: $article-grit-arrow-width;
  height: $article-grit-arrow-width;
  @if $direction == 'left' {
    left: -1*($article-grit-arrow-width / 2);
  } @else {
    right: -1*($article-grit-arrow-width / 2);
  }
  margin-top: -1*($article-grit-arrow-width / 2);;
  top: 50%;
  background-color: $color;
  @include rotate(45);
}
