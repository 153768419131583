.post-content h1 {
  text-align: center;
  margin-bottom: 0;
  font-size: 28px;
}

.post-content  .fl-separator {
  border-top: 2px solid #000000;
  width: 50%;
  margin: 20px auto 20px;
  display: block;
}

.post-content .wp-post-image {
  float: none;
  margin: 0;
}

@include min($s) {

  .post-content h1 {
    text-align: left;
    font-size: 40px;
  }

  .post-content  .fl-separator {
    width: 25%;
    margin: 20px 0 20px 0;
    display: inline-block;
  }

  .post-content .wp-post-image {
    max-width: 50% !important;
    float: right !important;
    margin: 5px 0 20px 20px !important;
  }
}
