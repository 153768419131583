$claim-font-sizes: (
        null : 21px,
        m: 28px,
        l: 38px,
        xl: 46px,
        xxl: 56px,
);
$counter-font-sizes: (
        null : 15px,
        s: 16px,
        m: 21px,
        l : 28px,
        xl: 32px
);

.fl-row.home > .fl-row-content-wrap > .fl-row-content > .fl-col-group {
  > .fl-col {
    flex-wrap: wrap;
    &:nth-child(odd) {
      width: 67% !important;
      @include min($xl) {
        width: 70% !important;
      }
    }
    &:nth-child(even) {
      align-items: flex-start;
      width: 33% !important;
      @include min($xl) {
        width: 30% !important;
      }
    }
  }
}

.main-slide {
  > .fl-col-content {
    background-color: #f4f4f4 !important;
    padding-left: 40% !important;
  }

  .uabb-fancy-text-main {
    text-align: center;
    width: 100% !important;
  }

  h1 {
    text-transform: uppercase;
    @include responsive-font(3.3vw, 21px, 64px, 32px);
  }

  .fl-number-before-text {
    margin-bottom: 0;
  }

  .fl-number-after-text {
    margin-top: -6px;
  }

  .fl-number-string {

    display: inline-block;
    font-weight: 700;
    margin-top: -12px;

    .fl-number-open, .fl-number-close {
      @include responsive-font(3.8vw, 16px, 72px, 32px);
      display: inline-block;
      line-height: 1.1;
    }
    .fl-number-open {
      padding-right: 15px;
    }
    .fl-number-close {
      padding-left: 15px;
    }


    @include responsive-font(2.7vw, 16px, 52px, 32px);

    .fl-number-int {
      display: inline-block;
    }
  }
}

@include min($xl) {
  .col-services {
    flex-direction: column;
    > div.fl-col-content {
      max-width: 75%;
      align-self: flex-end;
    }
  }

  .col-partners {
    > .fl-col-content {
      > div {
        height: 100%;
      }
    }
  }

  .col-partners-title {
    background-color: $gray !important;
  }

}